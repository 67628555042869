import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button
} from '@material-ui/core';
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from '@coreui/react-pro'
import { UsersService } from 'services/UsersAuth';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ViewTrainingClasses from './ViewTrainingMCQ';
import ViewTrainingMCQ from './ViewTrainingMCQ';
import { RiIndeterminateCircleLine } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const coursesData = [
  { id: 1, name: 'Networking' },
  { id: 2, name: 'CyberSecurity' },
  { id: 3, name: 'Cloud Architect and Security' },
  { id: 4, name: 'Cloud DevOps' }
  // Add more courses as needed
];

const TrainingProgramMCQ = () => {

  const location = useLocation();
  // const { class, coarse } = location?.state || {};
  console.log("location?.state", location);
  const classes = useStyles();
  const [CoarseID, setCoarseID] = useState();
  const [coarseName, setCoarseName] = useState();
  const [ClassID, setClassID] = useState();
  const [TrainingCoarses, setTrainingCoarses] = useState();
  const [TrainingClasses, setTrainingClasses] = useState();
  const [deleteID, setDeleteID] = useState();
  const [visible, setVisible] = useState(false);
  const [show, setHide] = useState(1);









  const getTrainingCoarses = async () => {
    const resp = await UsersService.getTrainingCoarse();
    setTrainingCoarses(resp?.data?.data)
    console.log("resp?.data?.data+++", resp?.data?.data);
  }

  const getTrainingCoarsesClass = async (data) => {
    let apiData = { course_id: data?.id }
    const resp = await UsersService.viewCourseClasses(apiData);
    setCoarseID(data?.id)
    setHide(2)
    setCoarseName(data?.title)
    setTrainingClasses(resp?.data?.data)
  }

  const deleteTrainingCoarses = async (id) => {
    const resp = await UsersService.deleteCoarse(id);
    getTrainingCoarses()
    setDeleteID()
    setVisible(false)
  }



  useEffect(() => {
    getTrainingCoarses()
  }, [])

  const goBack = () => {
    setHide(1)
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        show == 1 && <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {TrainingCoarses?.map((item, index) => {
              return (
                <Grid item xs={4} onClick={() => { getTrainingCoarsesClass(item) }}>
                  <div class="card position-relative"  >
                    {/* <a class="btn btn bg-danger position-absolute top-0 end-0" onClick={() => { setDeleteID(item?.id); setVisible(true) }}>Delete</a> */}
                    <img style={{ width: "100%", height: "250px" }} src={item?.course_img} class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">{item?.title}</h5>
                      <h5 class="card-title">{item?.category}</h5>
                      <p className='badge rounded-pill bg-success'>Skill level : {item?.skill_level}</p>

                      <p class="card-text mcq_course_para">{ReactHtmlParser(item?.course_description)}</p>
                      <a href="#" class="btn btn-primary float-end">View Classes</a>
                    </div>
                  </div>
                </Grid>)
            })}
          </Grid>
        </Box>
      }
      {show == 2 && <a href="#" class="btn btn bg-danger float-end" onClick={() => { goBack() }}>Back</a>}
      {
        show == 2 &&

        < Box sx={{ width: '100%' }}>
          <h5 class="card-title mt-2">Traning Coarse Name : {coarseName}</h5>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {
              TrainingClasses?.map((item, index) => {
                return (
                  <Grid item xs={3} >
                    <div class="card position-relative"  >
                      {console.log("+++++item", item)}

                      {/* <video controls>
                        <source src={item?.coursevedioslist[0]?.vedio_file?.length > 0 && item?.coursevedioslist[0]?.vedio_file[0]} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video> */}
                      <div class="card-body">
                        <h5 class="card-title">Title : {item?.title}</h5>
                        <p className='badge rounded-pill bg-success'> Day-{index + 1}</p>

                        <p class="card-text">Course Duration : {item?.duration}</p>
                        <a href="#" class="btn btn-primary float-end" onClick={() => { setClassID(item?.id); setHide(3) }}>Add MCQ</a>
                      </div>
                    </div>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box >

        // TrainingClasses?.map((item, index) => {
        //   return (

        //     <div>
        //       <div className='mt-5' >
        //         <div className='float-end'>
        //           {/* <RiIndeterminateCircleLine /> */}
        //         </div>
        //         <div>Title : {item?.title}</div>
        //         <div>Course Duration : {item?.duration}</div>
        //         <hr />
        //       </div>

        //     </div>

        //   )
        // })
      }

      {
        show == 3 && <ViewTrainingMCQ setHide={setHide} coarseId={CoarseID} classId={ClassID} />
      }
      {/* < Footer /> */}
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to delete!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => deleteTrainingCoarses(deleteID)}>Yes</CButton>
        </CModalFooter>
      </CModal>
    </DashboardLayout >
  );
};

export default TrainingProgramMCQ;
