/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
  // CAvatar,
  CButton,
  CCard,
  CCardBody,
  // CCardSubtitle,
  // CCardTitle,
  CCol,
  // CDropdown,
  // CDropdownItem,
  // CDropdownMenu,
  // CDropdownToggle,
  // CProgress,
  CRow,
  CCardHeader,
  // CTable,
  // CTableBody,
  // CTableDataCell,
  // CTableHead,
  // CTableHeaderCell,
  // CTableRow,
  // CWidgetStatsA,
  // CForm,
  // CFormLabel,
  // CFormInput,
  // CFormText,
  // CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CDateRangePicker
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import { utils, writeFileXLSX } from "xlsx";

// Data
import AdminTableData from "layouts/admin/Data/AdminTableData";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'
import { useEffect, useState } from "react";
import { UsersService } from "services/UsersAuth";
import moment from 'moment';

function Admin() {

  const [admins, setAdmins] = useState();
  const [visible, setVisible] = useState(false)
  const [adminToDelete, setAdminToDelete] = useState()
  const [statusMark, setStatusMark] = useState({
    id: "",
    status: ""
  })
  const [startDate, setStartDate] = useState(new Date('2022-01-01'))
  const [endDate, setEndDate] = useState(new Date('2022-03-19'))
  const minDate = new Date(
    Math.min(
      admins?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )
  const maxDate = new Date(
    Math.max(
      admins?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )


  useEffect(() => {
    getUsersDetail()
  }, [])

  const deleteAdminById = async () => {
    const deleteCom = await UsersService.deleteAdmin(adminToDelete, 8)
    await getUsersDetail()
    setVisible(false)
  }

  const deleteAdminConfirmation = (id) => {
    setAdminToDelete(id)
    setVisible(true)
  }

  const changeStatus = async (id, status) => {
    await UsersService.ActivateDactivateAdmin(statusMark.id, statusMark.status)
    await getUsersDetail()
    setVisible(false)
  }

  const changeStatusConfirmation = async (id, status) => {
    setStatusMark({ id, status })
    setVisible(true)
  }

  const getUsersDetail = async () => {
    const adminList = await UsersService?.AdminList();
    setAdmins(adminList?.data?.data)
  }

  // const downloadPdf = ()=>{
  //   const doc = new jsPDF()

  // autoTable(doc, {
  //   head: [columns],
  //   body: [rows],
  // })
  // doc.save('table.pdf')
  // }


  const columns = [
    { key: 'status', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'id', _style: { width: '4%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'role_id', _style: { width: '22%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'fname', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'lname', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'email', _style: { width: '22%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'mobile', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'citizen', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'country', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    {
      key: 'created_at', _style: { width: '20%', fontSize: '16px' }, filter: (values, onChange) => {
        return (
          <CDateRangePicker
            footer
            size="sm"
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onStartDateChange={(date) => {
              setStartDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return endDate ? itemDate >= date && itemDate <= endDate : itemDate >= date
                }
                return true
              })
            }}
            onEndDateChange={(date) => {
              setEndDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return startDate ? itemDate <= date && itemDate >= startDate : itemDate <= date
                }
                return true
              })
            }}
          />
        )
      }, sorter: true,
    },    // { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    // { key: 'role', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  ]

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <CCardHeader>
          <CButton color="secondary" shape="rounded-pill" ><Link style={{ color: "white" }} to="/CreateNewAdmin">Create New Admin</Link></CButton>
        </CCardHeader>
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardBody>
                    <AdminTableData columns={columns} data={admins} changeStatusConfirmation={changeStatusConfirmation} changeStatus={changeStatus} deleteAdminConfirmation={deleteAdminConfirmation} />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </SoftBox>

          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4">
                <CCardBody>
                  {/* <usersTableData /> */}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </SoftBox>
        <Footer />
      </DashboardLayout>
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to delete!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => deleteAdminById()}>Yes</CButton>
        </CModalFooter>
      </CModal>

      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to {statusMark.status == 1 ? "active" : "deactive"} this admin!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => { changeStatus() }}>Yes</CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default Admin;

