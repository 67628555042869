import React from 'react'
import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Grid from "@mui/material/Grid";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
      CitySelect,
      CountrySelect,
      StateSelect,
      LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";


// Authentication layout components
// import Socials from "layouts/authentication/components/Socials";
import Separator from "../../authentication/components/Separator";
import { CAlert } from '@coreui/react'
// Images
import curved6 from "../../../assets/images/curved-images/curved14.jpg";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { UsersService } from 'services/UsersAuth';
import SoftAlert from 'components/SoftAlert';
import toast, { Toaster } from 'react-hot-toast';
import {
      CAvatar,
      CButton,
      CCard,
      CCardBody,
      CCardSubtitle,
      CCardTitle,
      CCol,
      CDropdown,
      CDropdownItem,
      CDropdownMenu,
      CDropdownToggle,
      CProgress,
      CRow,
      CCardHeader,
      CTable,
      CTableBody,
      CTableDataCell,
      CTableHead,
      CTableHeaderCell,
      CTableRow,
      CWidgetStatsA,
      CForm,
      CFormLabel,
      CFormInput,
      CFormText,
      CFormCheck,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CModalTitle,
} from '@coreui/react-pro'

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const StudentEditForm = ({ data, closeEditForm, getUsersDetail }) => {
      const [age, setAge] = React.useState('');
      const [registerData, setRegisterData] = useState({
            role_id: data.id,
            id: data?.id,
            fname: data?.fname,
            lname: data?.lname,
            email: data?.email,
            mobile: data?.mobile,
            dob: data?.dob,
            citizen: data?.citizen,
            country: data?.country,
            state: data?.state,
            city: data?.city,
      })

      const [countryid, setCountryid] = useState(0);
      const [stateid, setstateid] = useState(0);
      const [error, setError] = useState(false);
      const [agreement, setAgremment] = useState(true);


      const [phoneNumber, setPhoneNumber] = useState('');
      const [formattedNumber, setFormattedNumber] = useState('');
      const [countryCode, setCountryCode] = useState();
      const [isValid, setIsValid] = useState(true);


      const handleChange = (event) => {
            setAge(event.target.value);
      };
      const handleSetAgremment = () => setAgremment(!agreement);
      useEffect(() => {
            console.log("registerData", registerData);
      }, [registerData])

      const createAdminApi = async () => {
            setError(false)
            var bodyFormData = new FormData();
            bodyFormData.append('id', registerData?.id);
            bodyFormData.append('fname', registerData?.fname);
            bodyFormData.append('lname', registerData?.lname);
            bodyFormData.append('mobile', registerData?.mobile);
            // bodyFormData.append('role_id', '3');
            bodyFormData.append('linkdin', registerData?.linkdin);

            try {
                  const resp = await UsersService.updateUserDetail(bodyFormData)
                  console.log(resp);
                  if (resp.status == 200) {
                        getUsersDetail()
                        closeEditForm()
                        setRegisterData({
                              role_id: "",
                              fname: "",
                              lname: "",
                              countryCode: "+91",
                              mobile: "",
                              dob: "",
                              linkdin: "",
                              id: ""

                        })
                        toast.success('Successfully created!', {
                              position: "top-right"
                        })
                  }
            } catch (error) {
                  setError(true)
                  toast.error('Something went wrong!', {
                        position: "top-right"
                  })
            }
      }


      const formatWithHyphen = (phoneNumber) => {
            // Use formatPhoneNumberIntl to format the phone number
            const formattedNumber = formatPhoneNumberIntl(phoneNumber);

            // Replace the first space with a hyphen
            return formattedNumber.replace(' ', '-');
      };

      const handlePhoneNumberChange = (value) => {

            const phoneNumber = parsePhoneNumberFromString(value ? value : "");
            if (phoneNumber) {
                  const { country, nationalNumber } = phoneNumber;

                  const formattedNumber = formatWithHyphen(value);
                  // Perform validation for Indian numbers
                  if (country === 'IN') {
                        if (/^[6-9]\d{9}$/.test(nationalNumber)) {
                              setIsValid(true);
                              setFormattedNumber(formattedNumber);
                              setPhoneNumber(formattedNumber);
                              // setErrorMessage('');
                        } else {
                              setIsValid(false);
                              // setErrorMessage('Invalid Indian phone number. It must start with 6-9 and be 10 digits long.');
                        }
                        return;
                  }
            }

            if (value) {
                  const formatted = formatWithHyphen(value);
                  setFormattedNumber(formatted);
                  setPhoneNumber(value);
                  setRegisterData((prevSettings) => ({
                        ...prevSettings,
                        mobile: value
                  }));

                  // Validate the phone number
                  const valid = isValidPhoneNumber(value);
                  setIsValid(valid);
            } else {
                  setPhoneNumber('');
                  setFormattedNumber('');
                  setIsValid(true); // Reset validity
            }
      };



      return (
            <>

                  {/* <DashboardLayout> */}
                  <Toaster />
                  {/* <CButton className='float-end' onClick={() => { back() }}>back</CButton> */}

                  <CAlert color="danger" dismissible visible={error} onClose={() => setError(false)}>
                        Something went wrong!
                  </CAlert>
                  <Grid container justifyContent='center'>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                              <Card>
                                    <SoftBox p={3} mb={1} textAlign="center">
                                          <SoftTypography variant="h5" fontWeight="medium">
                                                Edit Student Details
                                          </SoftTypography>
                                    </SoftBox>
                                    {/* <SoftBox mb={2}>
                                              <Socials />
                                            </SoftBox> */}
                                    {/* <Separator /> */}
                                    <SoftBox pt={2} pb={3} px={3}>
                                          <SoftBox component="form" role="form">
                                                <SoftBox mb={2}>
                                                      <SoftInput placeholder="First Name" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,
                                                                  fname: e.target.value
                                                            }));
                                                      }}
                                                            value={registerData.fname} />
                                                </SoftBox>
                                                <SoftBox mb={2}>
                                                      <SoftInput placeholder="Last Name" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,
                                                                  lname: e.target.value
                                                            }));
                                                      }}
                                                            value={registerData.lname} />
                                                </SoftBox>
                                                {/* <SoftBox mb={2}>
                                                      <SoftInput placeholder="University E-mail" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,
                                                                  email: e.target.value
                                                            }));
                                                      }}
                                                            value={registerData.email} />
                                                </SoftBox> */}
                                                {/* <SoftBox mb={2}>
                                                            <SoftInput type="password" placeholder="Password" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        password: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.password} />
                                                      </SoftBox> */}
                                                {/* <SoftBox mb={2}>
                                                            <SoftInput type="number" placeholder="countryCode" onChange={(e) => {
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        countryCode: e.target.value
                                                                  }));
                                                            }}
                                                                  value={registerData.countryCode} />
                                                      </SoftBox> */}
                                                <SoftBox mb={2}>
                                                      {/* <SoftInput type="number" placeholder="mobile" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,
                                                                  mobile: e.target.value
                                                                  // mobile: Number(e.target.value)
                                                            }));
                                                      }}
                                                            value={registerData.mobile} /> */}


                                                      <PhoneInput
                                                            style={{
                                                                  width: '100%',
                                                                  padding: '0px 0px 0 20px',
                                                                  border: '1px solid #e2eaf2',
                                                                  borderRadius: '8px',
                                                            }}
                                                            inputstyle={{
                                                                  padding: '0px 0px',
                                                                  border: 'none',
                                                            }}
                                                            name="phone"
                                                            defaultCountry={'US'}
                                                            // PhoneInputCountryFlag-height="5"
                                                            value={registerData.mobile}
                                                            // onChange={setPhoneNumber}
                                                            onChange={handlePhoneNumberChange}
                                                            required
                                                            maxlength="25" />

                                                </SoftBox>
                                                <SoftBox mb={2}>
                                                      <SoftInput type="text" placeholder="Linkedin url" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,

                                                                  linkdin: e.target.value
                                                            }));
                                                      }}
                                                            value={registerData.linkdin} />
                                                </SoftBox>
                                                {/* <SoftBox mb={2}>
                                                      <SoftInput placeholder="Educations" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,
                                                                  Educations: e.target.value
                                                            }));
                                                      }}
                                                            value={registerData.Educations} />
                                                </SoftBox> */}
                                                {/* <SoftBox mb={2}>
                                                      <SoftInput placeholder="University" onChange={(e) => {
                                                            setRegisterData((prevSettings) => ({
                                                                  ...prevSettings,
                                                                  University: e.target.value
                                                            }));
                                                      }}
                                                            value={registerData.University} />
                                                </SoftBox> */}





                                                <SoftBox mt={4} mb={1}>
                                                      <SoftButton variant="gradient" color="dark" fullWidth onClick={() => { createAdminApi() }}>
                                                            Save Edit
                                                      </SoftButton>
                                                </SoftBox>
                                          </SoftBox>
                                    </SoftBox>
                              </Card>

                        </Grid>
                  </Grid>
                  {/* </DashboardLayout> */}

            </>
      )
}

export default StudentEditForm