// /**
// =========================================================
// * Soft UI Dashboard React - v4.0.1
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import "assets/css/style.css"
import { useNavigate } from "react-router-dom";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { AuthService } from "services/Auth";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import login_2 from "assets/images/curved-images/banner.png"
import OtpInput from "components/otp";
import toast, { Toaster } from 'react-hot-toast';

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [otp, setOtp] = useState();
  const [steps, setSteps] = useState(1);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate()


  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("user_data"))
    if (localStorageData) {
      navigate('/')
    }
  }, [])



  const sendOTP = async () => {
    // console.log("formattedNumberformattedNumber", formattedNumber);

    try {
      const response = await fetch('https://rjback.freshgrad.com/adminapi/send-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "type": "login", email: email, password: password }),
      });

      const result = await response.json();
      console.log("result", result)
      if (result.statusCode == 200) {
        // alert('OTP sent!');
        toast.success(result?.msg);
        setSteps(2)
      } else {
        // alert('Failed to send OTP.');
        toast.error('Failed to send OTP.');
      }
    } catch (error) {
      console.error(error);
      // alert('An error occurred.');
      toast.error('Something went wrong');
      // setSteps(2)
    }
  };


  const verifyOTP = async () => {
    try {
      const response = await fetch('https://rjback.freshgrad.com/adminapi/confirm-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "type": "login",
          email: email,
          otp: otp,
        }),
      });

      const result = await response.json();
      // console.log("result", result);

      if (result?.data?.isOtpMatch == true) {
        // alert('OTP verified!');
        toast.success('OTP verified!');
        LoginApi()
        // setSteps(3)
      } else {
        // alert(result.message || 'Failed to verify OTP.');
        toast.error(result?.msg);
      }
    } catch (error) {
      console.error(error);
      // alert('An error occurred.');
      toast.error('Something went wrong');
      // setSteps(3)
    }
  };

  const LoginApi = async () => {
    const response = await AuthService.login(email, password);

    // Store token and user data in localStorage first
    if (response?.data?.statusCode == 200) {
      localStorage.setItem('access_token', response?.data?.token);
      localStorage.setItem('user_data', JSON.stringify(response?.data?.data?.admin));
    }
    // Verify storage was successful before continuing
    const accessToken = localStorage.getItem('access_token');
    const userData = JSON.parse(localStorage.getItem('user_data'));

    if (response?.data?.statusCode === '200' && accessToken && userData) {
      const roleId = userData?.role_id;

      // Proceed to navigate based on role_id
      if (roleId === 2) {
        navigate('/');
        window.location.reload()
      } else if (roleId === 3) {
        navigate('/support');
        window.location.reload()
      } else if (roleId === 4) {
        navigate('/training-program');
        window.location.reload()
      }
    } else {
      // Handle error case if status code is not 200
      response?.data?.msg && alert(response?.data?.msg);
    }
  };


  return (
    // <></>
    <CoverLayout
      title="Welcome back"
      description={steps == 1 ? "Enter your email and password " : "Enter your otp and sign in"}
      image={login_2}
    >
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <SoftBox component="form" role="form" >
        {steps == 1 && <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" onChange={(e) => { setEmail(e.target.value) }} value={email} placeholder="Email" required />
        </SoftBox>}



        {steps == 1 && <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" onChange={(e) => { setPassword(e.target.value) }} value={password} placeholder="Password" />
        </SoftBox>}

        {steps == 2 && <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Otp
            </SoftTypography>
          </SoftBox>
          <OtpInput length={6} onChange={setOtp} />
        </SoftBox>}


        {steps == 1 && <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>}
        <SoftBox mt={4} mb={1}>
          {steps == 1 && <SoftButton variant="gradient" color="info" fullWidth onClick={() => { sendOTP(); }}>
            send otp
          </SoftButton>}
          {steps == 2 && <SoftButton variant="gradient" color="info" fullWidth onClick={() => { verifyOTP(); }}>
            verify otp
          </SoftButton>}
        </SoftBox>
        {/* <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox> */}
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;







