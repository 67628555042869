import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button
} from '@material-ui/core';
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from '@coreui/react-pro'
import { UsersService } from 'services/UsersAuth';
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { red } from '@mui/material/colors';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import toast, { Toaster } from 'react-hot-toast';
import { styled } from '@mui/system';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const coursesData = [
  { id: 1, name: 'Networking' },
  { id: 2, name: 'CyberSecurity' },
  { id: 3, name: 'Cloud Architect and Security' },
  { id: 4, name: 'Cloud DevOps' }
  // Add more courses as needed
];

const InterviewGuide = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [visible, setVisible] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [errors, setErrors] = useState({});
  const [interviewTemplate, setInterviewTemplate] = useState({
    title: "",
    guide_image: "",
    long_description: "",
    guide_pdf: "",
    is_admin: "1"
  });
  const [interviewFullList, setInterviewFullList] = useState([]);

  const handleEditClick = (course) => {
    setSelectedCourse(course);
    setEditedName(course.name);
    setOpen(true);
  };

  useEffect(() => {
    InterviewGuideAll()
  }, [])


  const InterviewGuideAll = async () => {
    const InterviewGuideList = await UsersService.getInterviewGuide()
    console.log("InterviewGuideList", InterviewGuideList?.data?.data);
    setInterviewFullList(InterviewGuideList?.data?.data)

  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const formErrors = validateForm();
    var bodyFormData = new FormData();
    bodyFormData.append('title', interviewTemplate.title);
    bodyFormData.append('guide_image', interviewTemplate.guide_image);
    bodyFormData.append('long_description', interviewTemplate.long_description);
    bodyFormData.append('guide_pdf', interviewTemplate.guide_pdf);
    bodyFormData.append('is_admin', "1");
    if (Object.keys(formErrors).length === 0) {
      try {
        const addInterviewGuide = await UsersService.SaveInterviewGuide(bodyFormData)
        if (addInterviewGuide.status == 200) {
          setInterviewTemplate({
            title: "",
            guide_image: "",
            long_description: "",
            guide_pdf: "",
            is_admin: "1"
          })
          InterviewGuideAll()
          handleClose();
          toast.success('Successfully created!', {
            position: "top-right"
          })
        }
      } catch (error) {
        toast.error('Something went wrong!', {
          position: "top-right"
        })
      }
    } else {
      // Set errors and prevent form submission
      setErrors(formErrors);
    }
    // console.log("addInterviewGuide", addInterviewGuide);

  };

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const deleteInterviewGuide = async (data) => {
    let apiData = {
      id: data
    }
    const deleteInterview = await UsersService.InterviewGuideDeleted(apiData)
    setVisible(false)
    InterviewGuideAll()
  }

  const validateForm = () => {
    let formErrors = {};

    if (!interviewTemplate.title) {
      formErrors.title = "Title is required";
    }
    if (!interviewTemplate.guide_image) {
      formErrors.guide_image = "Guide image is required";
    }
    if (!interviewTemplate.long_description) {
      formErrors.long_description = "Description is required";
    }
    if (!interviewTemplate.guide_pdf) {
      formErrors.guide_pdf = "Guide pdf  is required";
    }
    return formErrors;
  };

  const TwoLineEllipsis = styled(Typography)({
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Limits to two lines
    textOverflow: 'ellipsis',
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />

      <SoftBox py={3}>
        <SoftBox mb={3}>
          <CCardHeader>
            <strong> Add Interview Guide</strong>
          </CCardHeader>
          <CCardBody>
            {/* <List className={classes.root}>
              {coursesData.map((course) => (
                <ListItem button key={course.id} onClick={() => handleEditClick(course)}>
                  <ListItemText primary={course.name} />
                </ListItem>
              ))}
            </List> */}
            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" role="form">
                <SoftBox mb={2}>
                  <SoftTypography>Guide Title*</SoftTypography>
                  <SoftInput placeholder="First Name" onChange={(e) => {
                    setInterviewTemplate((prevSettings) => ({
                      ...prevSettings,
                      title: e.target.value
                    }));
                  }}
                    value={interviewTemplate?.title} />
                  {errors.title && (
                    <SoftTypography color="error">
                      {errors.title}
                    </SoftTypography>
                  )}
                </SoftBox>


                <SoftBox mb={2}>
                  <SoftTypography>Guide guide_image*</SoftTypography>
                  <SoftInput placeholder="Last Name" onChange={(e) => {
                    setInterviewTemplate((prevSettings) => ({
                      ...prevSettings,
                      guide_image: e.target.files[0]
                    }));
                  }}
                    // value={interviewTemplate?.guide_image}
                    type="file" />
                  {errors.guide_image && (
                    <SoftTypography color="error">
                      {errors.guide_image}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography>Guide guide_pdf*</SoftTypography>
                  <SoftInput
                    type="file"
                    placeholder="Last Name" onChange={(e) => {
                      setInterviewTemplate((prevSettings) => ({
                        ...prevSettings,
                        guide_pdf: e.target.files[0]
                      }));
                    }}
                  // value={interviewTemplate?.guide_pdf}
                  />
                  {errors.guide_pdf && (
                    <SoftTypography color="error">
                      {errors.guide_pdf}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography>Guide long_description* </SoftTypography>
                  <SoftInput placeholder="Last Name" onChange={(e) => {
                    setInterviewTemplate((prevSettings) => ({
                      ...prevSettings,
                      long_description: e.target.value
                    }));
                  }}
                    value={interviewTemplate?.long_description} />
                  {errors.long_description && (
                    <SoftTypography color="error">
                      {errors.long_description}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftButton onClick={() => { handleSave() }}>Create</SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </CCardBody>
        </SoftBox>
        <Grid container spacing={2} className=''>
          {
            interviewFullList?.map((item) => {
              return (
                <Grid item lg={3}>
                  <Card >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={item?.guide_image}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {item?.title}
                      </Typography>
                      <TwoLineEllipsis variant="body1">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>{item?.long_description}</Typography>
                      </TwoLineEllipsis>


                      <CardActions>
                        <Button size="small" variant="contained">
                          <a href={item?.guide_pdf} target="_blank" rel="noopener noreferrer">View PDF</a>
                        </Button>
                        <Button size="small" variant="contained" onClick={() => { setDeleteID(item?.id); setVisible(true) }}>Delete</Button>
                      </CardActions>
                    </CardContent>

                  </Card>
                </Grid>

              )
            })
          }
        </Grid>
      </SoftBox >
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Course</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Course Name"
            type="text"
            fullWidth
            value={editedName}
            onChange={handleNameChange}
          />
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogContent>
      </Dialog> */}
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to delete!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => { deleteInterviewGuide(deleteID) }}>Yes</CButton>
        </CModalFooter>
      </CModal>

      < Footer />
    </DashboardLayout >
  );
};

export default InterviewGuide;
