import React, { useState, useEffect } from 'react'
import { UsersService } from 'services/UsersAuth';
import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import {
      CAvatar,
      CButton,
      CCard,
      CCardBody,
      CCardSubtitle,
      CCardTitle,
      CCol,
      CDropdown,
      CDropdownItem,
      CDropdownMenu,
      CDropdownToggle,
      CProgress,
      CRow,
      CCardHeader,
      CTable,
      CTableBody,
      CTableDataCell,
      CTableHead,
      CTableHeaderCell,
      CTableRow,
      CWidgetStatsA,
      CForm,
      CFormLabel,
      CFormInput,
      CFormText,
      CFormCheck,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CModalTitle,
} from '@coreui/react-pro'
import { RiIndeterminateCircleLine } from "react-icons/ri";
import toast, { Toaster } from 'react-hot-toast';




const ViewTrainingMCQ = ({ setHide, coarseId, classId }) => {



      const [TrainingClassesMCQ, setTrainingClassesMCQ] = useState();
      const [youtubeLink, setYoutubeLink] = useState([]);
      const [visible, setVisible] = useState(false);
      const [loader, setLoader] = useState(false);
      const [deleteID, setDeleteID] = useState();
      const [MCQFeilds, setMCQFeilds] = useState({
            question: "",
            options_a: "",
            options_b: "",
            options_c: "",
            options_d: "",
            options_e: "",
            right_option: ""
      });
      const getTrainingCoarsesClass = async () => {
            let apiData = {
                  course_id: coarseId,
                  class_id: classId
            }
            const resp = await UsersService.viewClassMCQ(apiData);
            setTrainingClassesMCQ(resp?.data?.data)
      }
      const goBack = () => {
            setHide(2)
      }
      const handleSave = async () => {
            setLoader(true)
            let apiData = {
                  course_id: coarseId,
                  class_id: classId,
                  question: MCQFeilds?.question,
                  options: {
                        ...(MCQFeilds?.options_a && { a: MCQFeilds.options_a }),
                        ...(MCQFeilds?.options_b && { b: MCQFeilds.options_b }),
                        ...(MCQFeilds?.options_c && { c: MCQFeilds.options_c }),
                        ...(MCQFeilds?.options_d && { d: MCQFeilds.options_d }),
                        ...(MCQFeilds?.options_e && { e: MCQFeilds.options_e })
                  },
                  right_option: MCQFeilds?.right_option?.toLowerCase()
            }
            const addInterviewGuide = await UsersService.saveCourseClassesMCQ(apiData)
            console.log("addInterviewGuide", JSON.parse(addInterviewGuide.data.data.options));
            if (addInterviewGuide.status == 200) {
                  setLoader(false)
                  setMCQFeilds({
                        question: "",
                        options_a: "",
                        options_b: "",
                        options_c: "",
                        options_d: "",
                        options_e: "",
                        right_option: ""
                  })
                  getTrainingCoarsesClass()
                  toast.success('Successfully sent!', {
                        position: "top-right"
                  })
            }
      }

      const handleRemove = (index) => {
            setYoutubeLink((prevyoutubeLink) => {
                  const newyoutubeLink = [...prevyoutubeLink];
                  newyoutubeLink.splice(index, 1);
                  return newyoutubeLink;
            });
      };

      const handleRemoveClass = async (id) => {
            const removeclass = await UsersService.deleteClassMCQ(id)
            console.log("removeclass", removeclass);
            if (removeclass.status == 200) {
                  getTrainingCoarsesClass()
                  setVisible(false)
            }
      };

      useEffect(() => {
            getTrainingCoarsesClass()
      }, [])

      return (
            <>
                  <CButton className='float-end btn bg-danger' onClick={() => { goBack() }}>Back</CButton>
                  <br />
                  <Toaster />
                  <SoftBox py={3}>
                        {
                              // loader ? <div class="d-flex justify-content-center">
                              //       <div class="spinner-border" role="status">
                              //             <span class="sr-only"></span>
                              //       </div>
                              // </div> :
                              <SoftBox mb={3}>
                                    <CCardHeader>
                                          <strong> Add MCQ </strong>
                                    </CCardHeader>
                                    <CCardBody>
                                          <SoftBox pt={2} pb={3} px={3}>
                                                <SoftBox component="form" role="form">
                                                      <SoftBox mb={2}>
                                                            <SoftTypography>Question</SoftTypography>
                                                            <textarea name="postContent" rows={4} cols={95} onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        question: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.question}
                                                            />
                                                      </SoftBox>

                                                      <SoftBox mb={2}>
                                                            <SoftTypography>Options</SoftTypography>
                                                            <SoftInput className="mb-2" placeholder="Option A" onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        options_a: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.options_a} />
                                                            <SoftInput className="mb-2" placeholder="Option B" onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        options_b: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.options_b} />
                                                            <SoftInput className="mb-2" placeholder="Option C" onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        options_c: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.options_c} />
                                                            <SoftInput className="mb-2" placeholder="Option D" onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        options_d: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.options_d} />
                                                            <SoftInput className="mb-2" placeholder="Option E" onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        options_e: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.options_e} />
                                                      </SoftBox>

                                                      <SoftBox mb={2}>
                                                            <SoftTypography>Right Option</SoftTypography>
                                                            <SoftInput placeholder="Right Option {a,b,c,d,e}" onChange={(e) => {
                                                                  setMCQFeilds((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        right_option: e.target.value
                                                                  }));
                                                            }}
                                                                  value={MCQFeilds?.right_option} />
                                                      </SoftBox>

                                                      <SoftBox mb={2}>
                                                            <CButton onClick={() => { handleSave() }}>Create</CButton>
                                                      </SoftBox>
                                                </SoftBox>
                                          </SoftBox>
                                    </CCardBody>
                              </SoftBox>}
                  </SoftBox >

                  {
                        TrainingClassesMCQ?.map((item, index) => {
                              return (

                                    !loader && <div>
                                          <div >
                                                <div className='float-end'>
                                                      <RiIndeterminateCircleLine onClick={() => { setDeleteID(item?.id); setVisible(true) }} />
                                                </div>
                                                <div className='mb-3 text-danger'>Question {index + 1} : {item.question}</div>
                                                {JSON.parse(item?.options)?.a && <div className='text-warning'>Options A : {JSON.parse(item?.options).a}</div>}
                                                {JSON.parse(item?.options)?.b && <div className='text-warning'>Options B: {JSON.parse(item?.options).b}</div>}
                                                {JSON.parse(item?.options)?.c && <div className='text-warning'>Options C: {JSON.parse(item?.options).c}</div>}
                                                {JSON.parse(item?.options)?.d && <div className='text-warning'>Options D: {JSON.parse(item?.options).d}</div>}
                                                {JSON.parse(item?.options)?.e && <div className='text-warning'>Options E: {JSON.parse(item?.options).e}</div>}
                                                <div className='text-success mt-3'>Right Option: {item.right_option}</div>
                                                <hr />
                                          </div>

                                    </div>

                              )
                        })
                  }

                  <CModal
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <p>Are you sure you want to delete!</p>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisible(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => { handleRemoveClass(deleteID) }}>Yes</CButton>
                        </CModalFooter>
                  </CModal>
            </>
      )
}

export default ViewTrainingMCQ