import React, { useState, useEffect } from 'react'
import { UsersService } from 'services/UsersAuth';
import SoftBox from "components/SoftBox";
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from '@coreui/react-pro'
import { RiIndeterminateCircleLine } from "react-icons/ri";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import toast, { Toaster } from 'react-hot-toast';


const ViewFAQ = ({ }) => {
  const [TrainingClassesMCQ, setTrainingClassesMCQ] = useState();
  const [youtubeLink, setYoutubeLink] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [FAQ_User, setFAQ_User] = useState("student");
  const [MCQFeilds, setMCQFeilds] = useState({
    question: "",
    answer: ""
  });
  const getTrainingCoarsesClass = async () => {

    const resp = await UsersService.viewFAQ();
    setTrainingClassesMCQ(resp?.data?.data)
  }

  const handleSave = async () => {
    setLoader(true)
    let apiData = {
      question: MCQFeilds?.question,
      answer: MCQFeilds?.answer,
      user_type: FAQ_User,
    }
    const addInterviewGuide = await UsersService.saveFAQ(apiData)
    if (addInterviewGuide.status == 200) {
      setLoader(false)
      setMCQFeilds({
        question: "",
        answer: ""
      })
      getTrainingCoarsesClass()
      toast.success('Successfully sent!', {
        position: "top-right"
      })
    }
  }



  const handleRemoveClass = async (id) => {
    const removeclass = await UsersService.deleteFAQ(id)
    console.log("removeclass", removeclass);
    if (removeclass.status == 200) {
      getTrainingCoarsesClass()
      setVisible(false)
    }
  };

  useEffect(() => {
    getTrainingCoarsesClass()
  }, [])

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Toaster />
        <CButton className='float-end btn bg-danger' onClick={() => { }}>Back</CButton>
        <br />

        <SoftBox py={3}>
          {
            // loader ? <div class="d-flex justify-content-center">
            //       <div class="spinner-border" role="status">
            //             <span class="sr-only"></span>
            //       </div>
            // </div> :
            <SoftBox mb={3}>
              <CCardHeader>
                <strong> Add FAQ </strong>
                <button type="button" class={FAQ_User == "student" ? "btn bg-success" : "btn bg-primary"} onClick={() => { setFAQ_User("student") }}>Student</button>
                <button type="button" class={FAQ_User == "trainer" ? "btn bg-success" : "btn bg-primary"} onClick={() => { setFAQ_User("trainer") }}>Trainer</button>
                <button type="button" class={FAQ_User == "recruiter" ? "btn bg-success" : "btn bg-primary"} onClick={() => { setFAQ_User("recruiter") }}>Recruiter</button>
              </CCardHeader>
              <CCardBody>
                <SoftBox pt={2} pb={3} px={3}>
                  <SoftBox component="form" role="form">


                    <SoftBox mb={2}>
                      <SoftTypography>Question</SoftTypography>
                      <textarea name="postContent" rows={4} cols={95} onChange={(e) => {
                        setMCQFeilds((prevSettings) => ({
                          ...prevSettings,
                          question: e.target.value
                        }));
                      }}
                        value={MCQFeilds?.question}
                      />
                    </SoftBox>

                    <SoftBox mb={2}>
                      <SoftTypography>Answer</SoftTypography>
                      <textarea name="postContent" rows={4} cols={95} onChange={(e) => {
                        setMCQFeilds((prevSettings) => ({
                          ...prevSettings,
                          answer: e.target.value
                        }));
                      }}
                        value={MCQFeilds?.answer}
                      />
                    </SoftBox>


                    <SoftBox mb={2}>
                      <CButton onClick={() => { handleSave() }}>Create</CButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </CCardBody>
            </SoftBox>}
        </SoftBox >

        {
          TrainingClassesMCQ?.map((item, index) => {
            return (

              !loader && item.user_type == FAQ_User && <div>
                <div >
                  <div className='float-end'>
                    <RiIndeterminateCircleLine onClick={() => { setDeleteID(item?.id); setVisible(true) }} />
                  </div>
                  <div className='mb-3 text-danger'>Question {index + 1} : {item?.question}</div>
                  <div className='text-success mt-3'>Answer: {item?.answer}</div>
                  <hr />
                </div>

              </div>

            )
          })
        }

        <CModal
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="LiveDemoExampleLabel"
          alignment="center"
        >
          <CModalHeader>
            <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <p>Are you sure you want to delete!</p>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setVisible(false)}>
              No
            </CButton>
            <CButton color="primary" onClick={() => { handleRemoveClass(deleteID) }}>Yes</CButton>
          </CModalFooter>
        </CModal>
      </DashboardLayout>
    </>
  )
}

export default ViewFAQ