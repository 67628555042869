import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Auth0Provider } from '@auth0/auth0-react';

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      {/* <Auth0Provider
        domain="dev-qvsepk7q74l2i68o.us.auth0.com"
        clientId="5gEopRxO32ycAfpmPwpDqxGrspwOwYgY"
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      > */}
      <App />
      {/* </Auth0Provider> */}
    </SoftUIControllerProvider>
  </BrowserRouter>
);
