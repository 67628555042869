import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
      CAvatar,
      CButton,
      CCard,
      CCardBody,
      CCardSubtitle,
      CCardTitle,
      CCol,
      CDropdown,
      CDropdownItem,
      CDropdownMenu,
      CDropdownToggle,
      CProgress,
      CRow,
      CCardHeader,
      CTable,
      CTableBody,
      CTableDataCell,
      CTableHead,
      CTableHeaderCell,
      CTableRow,
      CWidgetStatsA,
      CForm,
      CFormLabel,
      CFormInput,
      CFormText,
      CFormCheck,
      CModal,
      CModalHeader,
      CModalBody,
      CModalFooter,
      CModalTitle
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { utils, writeFileXLSX } from "xlsx";

// Data

import BlacklistData from "./Data/BlacklistData";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useEffect, useState } from "react";
import { UsersService } from "services/UsersAuth";
import SoftInput from "components/SoftInput";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";
import { RiIndeterminateCircleLine } from "react-icons/ri";
import SoftToast from "components/SoftToast";
import toast, { Toaster } from 'react-hot-toast';




function Blacklist() {

      const [company, setCompany] = useState();
      const [visible, setVisible] = useState(false)
      const [statusFlag, setStatusFlag] = useState(false)
      const [selectedStatusData, setSelectedStatusData] = useState()
      const [editedStatus, setEditedStatus] = useState()
      const [companyToDelete, setCompanyToDelete] = useState()
      const [domainArr, setDomainArr] = useState([])
      const [callToast, setCallToast] = useState(false)
      const [registerData, setRegisterData] = useState({
            company_name: "",
            price: "",
            company_status: 2,
            company_domain: ""
      })
      const [errors, setErrors] = useState({});

      useEffect(() => {
            getUsersDetail()
      }, [])

      const getUsersDetail = async () => {
            const studentList = await UsersService?.getCompany();
            setCompany(studentList?.data?.data)
      }

      const changeStatus = async () => {
            let ApiData = {
                  id: selectedStatusData?.id,
                  company_name: selectedStatusData?.company_name,
                  company_domain: selectedStatusData?.company_domain,
                  price: selectedStatusData?.price,
                  company_status: editedStatus
            }
            await UsersService.ChangeStatusCompany(ApiData)
            await getUsersDetail()
            setStatusFlag(false)
      }

      const changeStatusConfirmation = (data) => {
            setSelectedStatusData(data)
            setStatusFlag(true)
      }

      const deleteCompanyConfirmation = (id) => {
            setCompanyToDelete(id)
            setVisible(true)
      }

      const deleteCompanyById = async () => {
            const deleteCom = await UsersService.deleteCompany(companyToDelete)
            await getUsersDetail()
            setVisible(false)
            console.log("deleteCom", deleteCom);
      }

      const createNewCompany = async () => {
            const formErrors = validateForm();
            let ApiData = {
                  company_name: registerData.company_name,
                  price: registerData.price,
                  company_status: registerData.company_status,
                  company_domain: domainArr
            }
            if (Object.keys(formErrors).length === 0) {
                  try {
                        await UsersService.createCompany(ApiData)
                        await getUsersDetail()
                        setCallToast(true)
                        setRegisterData({
                              company_name: "",
                              price: "",
                              company_status: "",
                              company_domain: ""
                        })
                        setDomainArr([])
                        toast.success('Successfully sent!', {
                              position: "top-right"
                        })
                  } catch (error) {
                        toast.error('Something went wrong!', {
                              position: "top-right"
                        })
                  }
            } else {
                  // Set errors and prevent form submission
                  setErrors(formErrors);
            }

      }

      const handleRemove = (index) => {
            setDomainArr((prevDomainArr) => {
                  const newDomainArr = [...prevDomainArr];
                  newDomainArr.splice(index, 1);
                  return newDomainArr;
            });
      };

      function validateDomain(domain) {
            const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
            return domainRegex.test(domain);
      }

      const validateForm = () => {
            let formErrors = {};

            if (!registerData.company_name) {
                  formErrors.company_name = "Company Name is required";
            }

            if (domainArr.length === 0) {
                  formErrors.company_domain = "At least one domain is required";
            }

            if (domainArr.length > 0) {
                  let checkDom = domainArr?.map((item) => {
                        let check = validateDomain(item)
                        if (check == false) {
                              formErrors.company_domain = "Please enter valid domain";
                        }
                  })
            }

            if (!registerData.price || registerData.price < 0) {
                  formErrors.price = "Price cannot be in negatives";
            }

            return formErrors;
      };



      const columns = [
            { key: 'company_status', label: 'Status (1 for blacklist , 2 for whitelist)', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'id', _style: { width: '4%', fontSize: '16px' }, filter: true, sorter: false, },
            { key: 'company_name', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: true, },
            { key: 'price', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: true, },
            { key: 'company_domain', _style: { width: '18%', fontSize: '16px' }, filter: true, sorter: false, },
            // { key: 'mobile', _style: { width: '10%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'citizen', _style: { width: '10%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'country', _style: { width: '10%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'start_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            // { key: 'role', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
            { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
      ]

      return (
            <>
                  <DashboardLayout>
                        {callToast ?? <SoftToast />}
                        <DashboardNavbar />
                        <Toaster />
                        <SoftBox py={3}>

                              <CCardHeader>
                                    <strong>Add New Company</strong> <small></small>
                              </CCardHeader>
                              {/* creating new company with price  */}

                              <SoftBox pt={2} pb={3} px={3}>
                                    <SoftBox component="form" role="form">
                                          <SoftBox mb={2}>
                                                <SoftTypography>Company Name</SoftTypography>
                                                <SoftInput placeholder="First Name" onChange={(e) => {
                                                      setRegisterData((prevSettings) => ({
                                                            ...prevSettings,
                                                            company_name: e.target.value
                                                      }));
                                                }}
                                                      value={registerData.company_name} />
                                                {errors.company_name && (
                                                      <SoftTypography color="error">
                                                            {errors.company_name}
                                                      </SoftTypography>
                                                )}
                                          </SoftBox>
                                          <SoftBox mb={2} >
                                                <SoftTypography>Company Domain</SoftTypography>
                                                <SoftInput placeholder="Write your domain and press enter" onChange={(e) => {
                                                      setRegisterData((prevSettings) => ({
                                                            ...prevSettings,
                                                            company_domain: e.target.value
                                                      }));
                                                }}
                                                      onKeyDown={(e) => {
                                                            if (e.key === "Enter" && e.target.value) {
                                                                  setDomainArr([...domainArr, e.target.value]);
                                                                  setRegisterData((prevSettings) => ({
                                                                        ...prevSettings,
                                                                        company_domain: ""
                                                                  }));
                                                            }
                                                      }}
                                                      value={registerData.company_domain} />
                                                {errors.company_domain && (
                                                      <SoftTypography color="error">
                                                            {errors.company_domain}
                                                      </SoftTypography>
                                                )}
                                          </SoftBox>
                                          {
                                                domainArr?.map((item, index) => {
                                                      return (
                                                            <SoftTypography key={index}>Domain {index + 1} - {item}   <RiIndeterminateCircleLine onClick={() => { handleRemove(index) }} />
                                                                  <span style={{ color: "red" }}>{!validateDomain(item) ? ' Not a valid domain !' : ""}</span>
                                                            </SoftTypography>
                                                      )
                                                })
                                          }
                                          <SoftBox mb={2}>
                                                <SoftTypography>Price ($)</SoftTypography>
                                                <SoftInput placeholder="Last Name" type="number" onChange={(e) => {
                                                      setRegisterData((prevSettings) => ({
                                                            ...prevSettings,
                                                            price: e.target.value
                                                      }));
                                                }}
                                                      value={registerData.price} />
                                                {errors.price && (
                                                      <SoftTypography color="error">
                                                            {errors.price}
                                                      </SoftTypography>
                                                )}
                                          </SoftBox>
                                          <SoftBox mb={2}>
                                                <FormControl>
                                                      <FormLabel id="demo-row-radio-buttons-group-label">Role</FormLabel>
                                                      <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={(e) => {
                                                                  setRegisterData((prevSettings) => (e.target.value && e.target.value == 1 ? {
                                                                        ...prevSettings,
                                                                        company_status: 1
                                                                  } : {
                                                                        ...prevSettings,
                                                                        company_status: 2
                                                                  }));
                                                            }}
                                                      >
                                                            <FormControlLabel value={1} control={<Radio />} label="BlackList" />
                                                            <FormControlLabel value={2} control={<Radio />} label="WhiteList" />

                                                      </RadioGroup>
                                                </FormControl>
                                          </SoftBox>
                                          <SoftBox mb={2}>
                                                <SoftButton onClick={() => { createNewCompany() }}>Create</SoftButton>
                                          </SoftBox>
                                    </SoftBox></SoftBox>

                              {/* creating new company with price  */}


                              <CRow>
                                    <CCol xs={12}>
                                          <CCard className="mb-4">
                                                <CCardBody>
                                                      <BlacklistData columns={columns} data={company} changeStatus={changeStatus} changeStatusConfirmation={changeStatusConfirmation} deleteCompanyConfirmation={deleteCompanyConfirmation} />
                                                </CCardBody>
                                          </CCard>
                                    </CCol>
                              </CRow>
                        </SoftBox>
                        <Footer />
                  </DashboardLayout>
                  <CModal
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <p>Are you sure you want to delete!</p>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setVisible(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => deleteCompanyById()}>Yes</CButton>
                        </CModalFooter>
                  </CModal>

                  <CModal
                        visible={statusFlag}
                        onClose={() => setStatusFlag(false)}
                        aria-labelledby="LiveDemoExampleLabel"
                        alignment="center"
                  >
                        <CModalHeader>
                              <CModalTitle id="LiveDemoExampleLabel">Edit Company</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                              <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Update Status</FormLabel>
                                    <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          onChange={(e) => {
                                                setEditedStatus(e.target.value)
                                          }}
                                    >
                                          <FormControlLabel value={1} control={<Radio />} label="BlackList" />
                                          <FormControlLabel value={2} control={<Radio />} label="WhiteList" />

                                    </RadioGroup>
                              </FormControl>
                        </CModalBody>
                        <CModalFooter>
                              <CButton color="secondary" onClick={() => setStatusFlag(false)}>
                                    No
                              </CButton>
                              <CButton color="primary" onClick={() => changeStatus()}>Yes</CButton>
                        </CModalFooter>
                  </CModal>
            </>

      );
}

export default Blacklist;

