/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/


// Soft UI Dashboard React layouts


import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
import Students from "layouts/student";
import SettingsNew from "layouts/settings";
import Trainer from "layouts/trainer"
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import LogIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Admin from "layouts/admin"

// Soft UI Dashboard React icons
import { GiConfirmed } from "react-icons/gi";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import Recruiter from "layouts/recruiter/index"
import Sessions from "layouts/sessions";
import StudentDetailsChangeForm from "layouts/studentRequest";
import ManageAccess from "layouts/manageAccess";
import TrainingProgram from "layouts/trainingProgram"
import SpaceShip from "examples/Icons/SpaceShip";
// import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";
// import Cube from "examples/Icons/Cube";`
import { Redirect } from "react-router-dom"
import CreateNewAdmin from "layouts/admin/createNewAdmin/CreateNewAdmin";
import Blacklist from "layouts/blacklist";
import UnivercityBlacklist from "layouts/univercityBlacklist";
import Jobs from "layouts/jobs";
import InterviewGuide from "layouts/interviewGuide";
import Logout from "services/Logout";
import TrainingProgramClass from "layouts/trainingProgramClass";
import TrainingProgramMCQ from "layouts/trainingProgramMCQ";
import Support from "layouts/support";
import ViewFAQ from "layouts/faq";
import Contactus from "layouts/contact";
import ProtectedRouter from "services/ProtectedRouter";
import ResetPassword from "layouts/resetPassword"
import PrivacyPolicy from "layouts/privacy";
import TermsAndConditions from "layouts/termsandconditions";
import CourseReview from "layouts/courseReview";
const userLocalData = JSON.parse(localStorage.getItem("user_data"))

const routes = [
  {
    // type: "collapse",
    // name: "Sign In",
    // key: "sign-in",
    route: "/authentication/sign-in",
    // icon: <Document size="12px" />,
    // component: localStorage.getItem('access_token') ? <Dashboard /> : <LogIn />,
    component: <LogIn />,
    // noCollapse: false,
  },
  (localStorage.getItem("user_data")) && {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <ProtectedRouter Component={Dashboard} path={"/authentication/sign-in"} />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Admin",
    key: "admin",
    route: "/admin",
    icon: <GiConfirmed size="12px" />,
    component: <Admin />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2 || userLocalData?.role_id === 3) && {
    type: "collapse",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <GiConfirmed size="12px" />,
    component: <Support />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Contact",
    key: "contact",
    route: "/contact",
    icon: <GiConfirmed size="12px" />,
    component: <Contactus />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "FAQ",
    key: "FAQ",
    route: "/faq",
    icon: <GiConfirmed size="12px" />,
    component: <ViewFAQ />,
    noCollapse: false,
  },

  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Students",
    key: "students",
    route: "/students",
    icon: <Office size="12px" />,
    component: <Students />,
    noCollapse: false,
  },
  // {
  //   type: "collapse",
  //   name: "Training Program",
  //   key: "training",
  //   route: "/training",
  //   icon: <Office size="12px" />,
  //   component: <TrainingProgram />,
  //   noCollapse: false,
  // },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Recruiter",
    key: "recruiter",
    route: "/recruiter",
    icon: <Shop size="12px" />,
    component: <Recruiter />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Mentors",
    key: "trainer",
    route: "/trainer",
    icon: <Office size="12px" />,
    component: <Trainer />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Mentor-Sessions",
    key: "sessions",
    route: "/sessions",
    icon: <Office size="12px" />,
    component: <Sessions />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Blacklist/Whitelist",
    key: "Blacklist/Whitelist",
    route: "/blacklist-whitelist",
    icon: <Office size="12px" />,
    component: <Blacklist />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "UnivercityBlacklist",
    key: "UnivercityBlacklist",
    route: "/univercity-blacklist",
    icon: <Office size="12px" />,
    component: <UnivercityBlacklist />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "jobs",
    key: "Jobs",
    route: "/jobs",
    icon: <Office size="12px" />,
    component: <Jobs />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2 || userLocalData?.role_id === 4) && {
    type: "collapse",
    name: "New-Training-course",
    key: "training-program",
    route: "/training-program",
    icon: <Office size="12px" />,
    component: <TrainingProgram />,
    noCollapse: false,
  },

  (localStorage.getItem("user_data") && userLocalData?.role_id === 2 || userLocalData?.role_id === 4) && {
    type: "collapse",
    name: "Course Review",
    key: "course-review",
    route: "/course-review",
    icon: <Office size="12px" />,
    component: <CourseReview />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2 || userLocalData?.role_id === 4) && {
    type: "collapse",
    name: "Add-classes",
    key: "add-classes",
    route: "/add-classes",
    icon: <Office size="12px" />,
    component: <TrainingProgramClass />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2 || userLocalData?.role_id === 4) && {
    type: "collapse",
    name: "Add-classes-MCQ",
    key: "add-classes-mcq",
    route: "/add-classes-mcq",
    icon: <Office size="12px" />,
    component: <TrainingProgramMCQ />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "interview-guide",
    key: "interview-guide",
    route: "/interview-guide",
    icon: <Office size="12px" />,
    component: <InterviewGuide />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Privacy-policy",
    key: "privacy-policy",
    route: "/privacy-policy",
    icon: <Office size="12px" />,
    component: <PrivacyPolicy />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Terms and Condition",
    key: "terms-and-condition",
    route: "/terms-and-condition",
    icon: <Office size="12px" />,
    component: <TermsAndConditions />,
    noCollapse: false,
  },


  // third milestone
  // {
  //   type: "collapse",
  //   name: "Training-coarses",
  //   key: "training-coarses",
  //   route: "/training-coarses",
  //   icon: <Office size="12px" />,
  //   component: <Trainer />,
  //   noCollapse: false,
  // },
  // {
  //   type: "collapse",
  //   name: "Queries",
  //   key: "Queries",
  //   route: "/queries",
  //   icon: <Office size="12px" />,
  //   component: <Trainer />,
  //   noCollapse: false,
  // },
  // third milestone

  // {
  //   type: "collapse",
  //   name: "ManageAccess",
  //   key: "manageAccess",
  //   route: "/manageAccess",
  //   icon: <Office size="12px" />,
  //   component: <ManageAccess />,
  //   noCollapse: false,
  // },
  // {
  //   type: "collapse",
  //   name: "Request Form",
  //   key: "changeDetails",
  //   route: "/changeDetails",
  //   icon: <Office size="12px" />,
  //   component: <StudentDetailsChangeForm />,
  //   noCollapse: false,
  // },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <Settings size="12px" />,
    component: <SettingsNew />,
    noCollapse: false,
  },

  {
    type: "collapse",
    name: "ResetPassword",
    key: "resetPassword",
    route: "/resetPassword",
    icon: <Settings size="12px" />,
    component: <ResetPassword />,
    noCollapse: false,
  },


  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   route: "/rtl",
  //   icon: <Settings size="12px" />,
  //   component: <RTL />,
  //   noCollapse: false,
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <CustomerSupport size="12px" />,
  //   component: <Profile />,
  //   noCollapse: false,
  // },



  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: false,
  // },
  {
    type: "collapse",
    name: "Log Out",
    key: "log-out",
    route: "/logout",
    icon: <SpaceShip size="12px" />,
    component: <Logout />,
    noCollapse: false,
  },
  (localStorage.getItem("user_data") && userLocalData?.role_id === 2) && {
    // type: "collapse",
    // name: "Sign Up",
    // key: "sign-up",
    route: "/CreateNewAdmin",
    // icon: <SpaceShip size="12px" />,
    component: <CreateNewAdmin />,
    // noCollapse: false,
  },

];

export default routes;
