import React, { useState } from 'react'
import { CBadge, CButton, CCardBody, CCollapse, CSmartTable } from '@coreui/react-pro'

import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { RiDeleteBin5Line } from 'react-icons/ri';
import { FiCheckCircle } from "react-icons/fi";
import moment from 'moment';


const AdminTableData = ({ columns, data, changeStatus, deleteAdminConfirmation, showDetail }) => {


  // function gg() {
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, utils.json_to_sheet(data));
  //   writeFileXLSX(wb, "data.xlsx");
  // }
  const [details, setDetails] = useState([])

  const getBadge = (status) => {
    switch (status) {
      case 1:
        return 'success'
      case 'Inactive':
        return 'secondary'
      case 'Pending':
        return 'warning'
      case 0:
        return 'danger'
      default:
        return 'primary'
    }
  }
  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

  return (
    <>
      {/* <button onClick={gg}>EXPORT DATA</button> */}
      <CSmartTable
        checkboxSelection
        sorterValue={{ column: 'name', state: 'asc' }}
        clickableRows
        tableProps={{
          striped: true,
          hover: true,
          className: 'add-this-class',
          responsive: true,
        }}
        activePage={1}
        // footer
        action
        items={data}
        columns={columns}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={5}
        columnSorter
        pagination
        scopedColumns={{
          created_at: (item) => (
            <td>
              <CCardBody >
                {/* {item.created_at} */}
                {moment(item.created_at).format("MM/DD/YYYY")}
              </CCardBody>
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge color={getBadge(item.getBadge)} onClick={() => { changeStatus(item.id, item.status == 1 ? 2 : 1) }}>{item.status == 1 ? 'Active' : 'Dactive'}</CBadge>
            </td>
          ),
          fname: (item) => (
            <td>
              <CCardBody  >{item.userdetail.fname}</CCardBody>
            </td>
          ),
          lname: (item) => (
            <td>
              <CCardBody  >{item.userdetail.lname}</CCardBody>
            </td>
          ),
          reply_status: (item) => (
            <td>
              <CBadge color={getBadge(item.admin_response ? 1 : 0)} onClick={() => { changeStatus(item.id, item.admin_response == 1 ? 2 : 1) }}>{item.admin_response ? 'Sent' : 'Not Sent'}</CBadge>
            </td>
          ),
          show_details: (item) => {
            return (

              <td className="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="square"
                  size="sm"
                  onClick={() => {
                    toggleDetails(item.id)
                  }}
                >
                  {details.includes(item.id) ? < BiSolidShow /> : <BiSolidHide />}
                </CButton>
              </td>
            )
          },
          details: (item) => {
            return (

              <CCollapse visible={details.includes(item.id)}>
                <CCardBody >
                  <h6>{item.fname}</h6>
                  {/* <p className="text-muted" style={{ fontSize: '14px' }}>User since: {item.registered}</p> */}
                  <CButton size="sm" color="info">
                    <FiCheckCircle onClick={() => { showDetail(item) }} />
                  </CButton>
                  {/* <CButton size="sm" color="danger" className="ml-1">
                    <RiDeleteBin5Line onClick={() => { deleteAdminConfirmation(item.id) }} />
                  </CButton> */}
                </CCardBody>
              </CCollapse>
            )
          },
        }}


        tableBodyProps={{
          className: 'align-middle',
          style: { fontSize: '13px' }
        }}
      />
    </>
  )
}

export default AdminTableData
