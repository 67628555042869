/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import {
  CAvatar,
  CButton,
  CCard,
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress,
  CRow,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CWidgetStatsA,
  CForm,
  CFormLabel,
  CFormInput,
  CFormText,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CDateRangePicker
} from '@coreui/react-pro'

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { utils, writeFileXLSX } from "xlsx";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
// import usersTableData from "layouts/tables/data/usersTableData";
import SmartTableBasicExample from "./data/usersTableData";
import StudentDetailsChangeForm from "../studentRequest/Data/StudentDetailsChangeForm";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import data from './data/data';
import { UsersService } from "services/UsersAuth";
import { useEffect, useState } from "react";
import moment from 'moment';
import { Label } from "recharts";
import StudentEditForm from "./data/StudentEditForm";

function Students() {
  // const { columns, rows } = authorsTableData;
  // const { columns: prCols, rows: prRows } = projectsTableData;
  // const { columns: urCols, rows: urRows } = usersTableData;
  const [students, setStudents] = useState();
  const [visible, setVisible] = useState(false);
  const [editableData, setEditableData] = useState();
  const [VisibleForm, setVisibleForm] = useState(false)
  const [statusMark, setStatusMark] = useState({
    id: "",
    status: ""
  })
  const [startDate, setStartDate] = useState(new Date('2022-01-01'))
  const [endDate, setEndDate] = useState(new Date('2022-03-19'))
  const minDate = new Date(
    Math.min(
      students?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )
  const maxDate = new Date(
    Math.max(
      students?.map((element) => {
        return new Date(Date.parse(element.created_at))
      }),
    ),
  )

  // const downloadPdf = ()=>{
  //   const doc = new jsPDF()

  // autoTable(doc, {
  //   head: [columns],
  //   body: [rows],
  // })
  // doc.save('table.pdf')
  // }

  //handling web scrapping for linked in profile
  const handlingLinkedinProfile = () => {

  }

  useEffect(() => {
    getUsersDetail()
  }, [])

  const changeStatus = async () => {
    await UsersService.ActivateDactivateUser(statusMark.id, statusMark.status)
    await getUsersDetail()
    setVisible(false)
  }

  const changeStatusConfirmation = async (id, status) => {
    setStatusMark({ id, status })
    setVisible(true)
  }

  const StudentFormPopup = async (data) => {
    console.log("data of student", data);
    setEditableData(data)
    setVisibleForm(true)
  }

  const getUsersDetail = async () => {
    const studentList = await UsersService?.UserList(2);
    setStudents(studentList?.data?.data)
  }

  const closeEditForm = () => {
    setVisibleForm(false)
  }

  const dateFilterFunction = (value, filter) => {
    const formattedDate = moment(value).format('MM/DD/YYYY');
    return formattedDate.includes(filter);
  };

  const SaveEdit = () => {

  }

  const columns = [
    { key: 'status', label: 'Status(1 for active 2 for deactive)', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'id', _style: { width: '4%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'fname', _style: { width: '9%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'lname', _style: { width: '9%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'email', label: "University email", _style: { width: '16%', fontSize: '16px' }, filter: true, sorter: true, },
    { key: 'mobile', _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'sd_linkedin', label: "linkdin", _style: { width: '10%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'educations', _style: { width: '13%', fontSize: '16px' }, filter: false, sorter: false, },
    { key: 'university', _style: { width: '13%', fontSize: '16px' }, filter: true, sorter: true, },

    { key: 'country', _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'state', _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'city', _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'sd_dob', label: "DOB", _style: { width: '10%', fontSize: '16px' }, filter: false, sorter: false, },
    { key: 'sd_nationality', label: "nationality", _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'sd_citizen', label: "Work Authorization", _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'sd_ethnicity', label: "ethnicity", _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'Training_program_1', label: "Training Program 1 Completed", _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    { key: 'Training_program_2', label: "Training Program 2 Completed", _style: { width: '6%', fontSize: '16px' }, filter: true, sorter: false, },
    {
      key: 'created_at', _style: { width: '20%', fontSize: '16px' }, filter: (values, onChange) => {
        return (
          <CDateRangePicker
            footer
            size="sm"
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onStartDateChange={(date) => {
              setStartDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return endDate ? itemDate >= date && itemDate <= endDate : itemDate >= date
                }
                return true
              })
            }}
            onEndDateChange={(date) => {
              setEndDate(date)
              onChange((item) => {
                if (date) {
                  const itemDate = new Date(Date.parse(item))
                  return startDate ? itemDate <= date && itemDate >= startDate : itemDate <= date
                }
                return true
              })
            }}
          />
        )
      }, sorter: true,
    },

    // { key: 'start_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    // { key: 'end_date', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    // { key: 'role', _style: { width: '25%', fontSize: '16px' }, filter: false, sorter: false, },
    { key: 'show_details', label: '', _style: { width: '1%', fontSize: '16px' }, filter: false, sorter: false, },
  ]

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <CCardHeader>
          {/* <strong>ADMIN USER DATA</strong> <small></small> */}
        </CCardHeader>
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <CRow>
              <CCol xs={12}>
                <CCard className="mb-4">
                  <CCardHeader>
                    <strong>TOTAL STUDENTS REGISTERED</strong> <small></small>
                  </CCardHeader>
                  <CCardBody>
                    <SmartTableBasicExample columns={columns} data={students} changeStatusConfirmation={changeStatusConfirmation} startDate={startDate} endDate={endDate} StudentFormPopup={StudentFormPopup} />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </SoftBox>
        </SoftBox>
        <Footer />
      </DashboardLayout>
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to {statusMark.status == 1 ? "active" : "deactive"} student!</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" onClick={() => { changeStatus() }}>Yes</CButton>
        </CModalFooter>
      </CModal>

      <CModal
        visible={VisibleForm}
        onClose={() => setVisibleForm(false)}
        aria-labelledby="LiveDemoExampleLabel"
        alignment="center"
      >
        {/* <CModalHeader>
          <CModalTitle id="LiveDemoExampleLabel">Edit Student Detail</CModalTitle>
        </CModalHeader> */}
        <CModalBody style={{ height: '400px', overflowY: 'scroll' }}>
          <StudentEditForm data={editableData} closeEditForm={closeEditForm} getUsersDetail={getUsersDetail} />
        </CModalBody>
        <CModalFooter>
          {/* <CButton color="secondary" onClick={() => setVisibleForm(false)}>
            No
          </CButton> */}
          <CButton color="primary" onClick={() => { setVisibleForm(false) }}>Close</CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default Students;

